import { defineStore } from 'pinia'

export const useUserStore = defineStore({
    id: 'user',
    state: () => ({
        // Load token and user from localStorage:
        token: localStorage.getItem('token'),
        user: localStorage.getItem('user'),
        refresh_token: localStorage.getItem('refresh_token'),
        refresh_expiry: localStorage.getItem('refresh_expiry'),
        admin_url: localStorage.getItem('admin_url'),
    }),
    actions: {
        getToken(){return this.token;},
        getUser(){return JSON.parse(this.user);},
        getRefreshToken(){return this.refresh_token;},
        getRefreshExpiresIn(){return this.refresh_expiry;},
        isAuthenticated(){return !!this.token;},
        setToken(token){
            this.token = token;
            localStorage.setItem('token', this.token);
        },
        setRefreshToken(refresh_token){
            this.refresh_token = refresh_token;
            localStorage.setItem('refresh_token', this.refresh_token);
        },
        setRefreshExpiresIn(refresh_expiry){
            this.refresh_expiry = refresh_expiry;
            localStorage.setItem('refresh_expiry', this.refresh_expiry);
        },
        removeToken(){
            this.token = null;
            localStorage.removeItem('token');
            localStorage.removeItem('refresh_token');
        },
        setUser(user){
            this.user = JSON.stringify(user);
            localStorage.setItem('user', this.user);
        },
        removeUser(){
            this.user = null;
            localStorage.removeItem('user');
        },
        getAdminURL(){
            if(this.isAuthenticated()) {
                this.admin_url = import.meta.env.VITE_ADMIN_ENDPOINT;
                localStorage.setItem('admin_url', this.admin_url);
            } else {
                this.admin_url = null;
                localStorage.removeItem('admin_url');
            }
            return this.admin_url;
        }
    },
} );
