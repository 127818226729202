import {createRouter, createWebHashHistory} from 'vue-router'
import {useUserStore} from "../store/user";


const routes = [
    {
        path: "/",
        component: () => import('../layouts/default/Default.vue'),
        children: [
            {
                path: "",
                name: "Home",
                component: () => import('../views/HomeView.vue')
            },
            {
                path: "menu-editor",
                name: "Menu Editor",
                component: () => import('../views/MenuEditView.vue')
            },
            {
                path: "menu-editor/beers",
                name: "Beers",
                component: () => import('../components/BeerList.vue'),
            },
            {
                path: "menu-editor/food",
                name: "Food",
                component: () => import('../views/FoodList.vue'),
            },
            {
                path: "logout",
                name: "Logout",
                component: () => import('../views/LogoutView.vue'),
                meta: {public: true}
            },
            {
                path: "menu-editor",
                name: "Menu",
                component: () => import('../views/MenuEditView.vue'),
                meta: {public: true}
            },

        ],
    },
    {
        path: "/login",
        name: "Login",
        component: () => import('../views/LoginView.vue'),
    },
    {
        path: "/menu",
        name: "Menu",
        component: () => import('../views/MenuView.vue'),
    },
    {
        // Catch-all (404):
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: () => import('../views/NotFound.vue'),
        meta: {public: true}
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    const userStore = useUserStore();
    if(!userStore.isAuthenticated() && !to.meta.public && to.name !== 'Login'){
        next({name: 'Login'});
    } else {
        next();
    }
});

export default router;
