
// Plugins
import pinia from './store';
import router from './router';
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import * as Sentry from "@sentry/vue";

// Local styles
import './assets/main.css';

// Color Theme:
const myCustomLightTheme = {
    dark: false,
    colors: {
        background: '#FFFFFF',       // White background
        surface: '#FCFCFC',          // White surface
        primary: '#00BD7E',          // Primary green color
        'primary-darken-1': '#009C69', // Slightly darker green for primary darken
        secondary: '#395C6B',        // Secondary lighter green
        'secondary-darken-1': '#1E2F38', // Slightly darker green for secondary darken
        error: '#B00020',            // Standard red for errors
        info: '#31485E',             // Standard blue for info
        success: '#00BD7E',          // Green for success, same as primary
        warning: '#FB8C00',          // Standard orange for warnings
    },
}
const myCustomDarkTheme = {
    dark: true,
    colors: {
        background: '#121212',       // Black background
        surface: '#121212',          // Black surface
        primary: '#4CAF50',          // Primary green color
        'primary-darken-1': '#388E3C', // Slightly darker green for primary darken
        secondary: '#8BC34A',        // Secondary lighter green
        'secondary-darken-1': '#689F38', // Slightly darker green for secondary darken
        error: '#B00020',            // Standard red for errors
        info: '#2196F3',             // Standard blue for info
        success: '#4CAF50',          // Green for success, same as primary
        warning: '#FB8C00',          // Standard orange for warnings
    },
}

const vuetify = createVuetify({
    components,
    directives,
    theme: {
        defaultTheme: 'myCustomLightTheme',
        themes: {
            myCustomLightTheme,
            myCustomDarkTheme,
        },
    },
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
        },
    }
})

// Components
import App from './App.vue'

// Composables
import { createApp } from 'vue'

const app = createApp(App)
    .use(vuetify)
    .use(pinia)
    .use(router)


/*app.config.errorHandler = (err, vm, info) => {
  console.error(`Global Vue errorHandler: ${info}`, err);
};*/

// Configure Sentry
Sentry.init({
    app: app,
    dsn: "https://ffb350294932c33be501142282f8b3d2@o4506507842682880.ingest.sentry.io/4506507844386816",
    integrations: [
        new Sentry.BrowserTracing(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    tracePropagationTargets: ["localhost", /^https:\/\/pivo\.establishmentbrewing\.ca\.ca\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


try {
    Sentry.addIntegration(new Sentry.Replay(
        {
            maskAllText: false,
            blockAllMedia: false,
            minReplayDuration: 5000,
        }
    ))
} catch (e) {
    console.error("Failed to add Sentry.Replay integration: ", e)
}

app.mount('#app')
