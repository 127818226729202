<template>
    <v-app>
        <router-view/>
    </v-app>
</template>

<style scoped>
header {
  line-height: 1.5;
}

.logo {
  display: block;
  margin: 0 auto 2rem;
}

@media (min-width: 1024px) {
  header {
    display: flex;
    place-items: center;
    padding-right: calc(var(--section-gap) / 2);
  }

  .logo {
    margin: 0 2rem 0 0;
  }

  header .wrapper {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
  }
}
</style>

<script>
import { reactive, onMounted } from 'vue';
import LogoEstbrew from "./components/icons/LogoEstbrew.vue";
import PivoWelcome from './components/PivoWelcome.vue'
import { useUserStore } from "./store/user.js";
import { useRouter } from 'vue-router';

export default {
    name: "App",
    components: {
        LogoEstbrew,
        PivoWelcome,
    },
    setup() {
        const userStore = useUserStore();
        const apiEndpoint = import.meta.env.VITE_GRAPHQL_ENDPOINT;
        const router = useRouter();
        const state = reactive({
            menuOpen: false,
            dataLoaded: false
        });

        onMounted(async () => {
            if(!userStore.isAuthenticated()){
                await router.push('/#/login');
            }
        });

        const toggleMenu = () => {
            state.menuOpen = !state.menuOpen;
        };

        const userSignOut = () => {
            userStore.removeToken();
            userStore.removeUser();
            router.push('/#/login');
        };

    return { state, toggleMenu, userSignOut };
  }
};
</script>
